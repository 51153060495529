<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout
      justify-center
      wrap>
      <v-flex md12>
        <form>
          <!-- General Info -->
          <material-card
            :title="$t('userProfile.userProfile')"
            color="green"
            flat
            full-width
            class="bg-white"
          >
            <!-- Box Body -->
            <v-form ref="form">
              <v-container py-0>
                <v-layout wrap>
                  <v-flex
                    md12
                    mt-3>
                    <avatar-component
                      :url-avatar="userProfile.avatarUrl"
                      @completed="handleCompleted"/>
                  </v-flex>
                  <v-flex
                    xs12
                    md4>
                    <v-text-field
                      v-model="userProfile.email"
                      :disabled="userProfile.id != 0 && !isNull(userProfile.id)"
                      :label="$t('user.email')"
                      class="purple-input"
                    />
                  </v-flex>
                  <!-- Last Name -->
                  <v-flex
                    xs12
                    md4>
                    <v-text-field
                      :rules="[requiredValue]"
                      v-model="userProfile.fullName"
                      :label="$t('user.fullname')"
                      class="purple-input required"
                    />
                  </v-flex>
                  <!-- Phone -->
                  <v-flex
                    xs12
                    md4>
                    <v-text-field
                      :rules="[requiredValue]"
                      v-model="userProfile.phone"
                      :label="$t('user.phone')"
                      :disabled="isModerator()"
                      class="purple-input required"
                    />
                  </v-flex>
                  <!-- Gender -->
                  <v-flex
                    xs12
                    md4>
                    <v-select
                      v-model="userProfile.gender"
                      :items="userProfile.genderList"
                      :label="$t('userProfile.gender')"
                      item-text="text"
                      item-value="value"
                    >
                      <template
                        slot="selection"
                        slot-scope="data">
                        {{ $t(data.item.text) }}
                      </template>
                      <template
                        slot="item"
                        slot-scope="data">
                        {{ $t(data.item.text) }}
                      </template>
                    </v-select>
                  </v-flex>
                  <!-- Date of birth -->
                  <v-flex
                    xs12
                    md4>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="userProfile.birthDateFormatted"
                          :label="$t('user.dob')"
                          persistent-hint
                          @blur="
                            date = parseDate(userProfile.birthDateFormatted)
                          "
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="birthDate"
                        no-title
                        @input="menu = false"
                      />
                    </v-menu>
                  </v-flex>
                  <!-- User role -->
                  <v-flex
                    xs12
                    md4>
                    <v-select
                      v-model="userProfile.roleIds"
                      :items="userProfile.roles"
                      :label="$t('decentralizationGroup.privilege')"
                      multiple
                      chips
                      disabled
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
            <!-- /.Box Body -->
            <!-- Box Footer -->
            <v-flex
              xs12
              md12>
              <v-btn
                class="white--text"
                color="error"
                style="margin-right: 5px"
                @click="onClose()"
              >
                <span class="btn-label">
                  <i
                    class="glyphicon glyphicon-remove"
                    style="margin-right: 5px"
                  />
                </span>
                {{ $t("common.close") }}
              </v-btn>
              <v-btn
                class="ma-2"
                color="warning"
                style="margin-right: 5px"
                @click="onLogOut()"
              >
                <span class="btn-label">
                  <i
                    class="glyphicon glyphicon-log-out"
                    style="margin-right: 5px"
                  />
                </span>
                {{ $t("common.logOut") }}
              </v-btn>
              <v-btn
                class="ma-2"
                color="success"
                style="margin-right: 5px"
                @click="saveUserProfile()"
              >
                <span class="btn-label">
                  <i
                    class="glyphicon glyphicon-ok"
                    style="margin-right: 5px" />
                </span>
                {{ $t("common.save") }}
              </v-btn>
              <v-btn
                class="ma-2"
                color="primary"
                style="margin-right: 5px"
                @click="onShowChangePassword()"
              >
                <span class="btn-label">
                  <i
                    class="glyphicon glyphicon-lock"
                    style="margin-right: 5px" />
                </span>
                {{ $t("user.changePassword") }}
              </v-btn>
            </v-flex>
          </material-card>
        </form>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <!-- ChangePasswordModal -->
    <change-password-modal ref="changePasswordModal"/>
  </v-container>
</template>
<script>
import stringUtils from 'utils/stringUtils'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
// import ToastType from 'enum/toastType'
import { mapActions, mapGetters } from 'vuex'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
import ToastType from 'enum/toastType'
import changePasswordModal from './ChangePasswordModal'
import Avatar from 'vue-avatar'
import avatarComponent from '@/components/avatarComponent'
import RoleType from 'enum/roleType'

export default {
  components: {
    changePasswordModal,
    Avatar,
    avatarComponent
  },
  data: () => ({
    menu: false,
    userProfile: {
      id: null,
      birthDateFormatted: null,
      privilege: null,
      gender: null,
      phone: null,
      fullName: null,
      email: null,
      genderList: stringUtils.GenderList,
      roles: [],
      roleIds: null,
      avatarUrl: ''
    },
    birthDate: null
  }),
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.birthDate)
    },
    ...mapGetters(['GET_CURRENT_ACCOUNT', 'GROUP_DATA_LIST', 'GET_SNACK_BAR'])
  },
  watch: {
    GROUP_DATA_LIST () {
      let data = this.GROUP_DATA_LIST
      for (let i = 0, size = data.length; i < size; i++) {
        let roleObj = {
          value: data[i].id,
          text: data[i].name
        }
        this.userProfile.roles.push(roleObj)
      }
    },
    birthDate (val) {
      this.userProfile.birthDateFormatted = this.formatDate(this.birthDate)
    },
    GET_CURRENT_ACCOUNT () {
      let data = this.GET_CURRENT_ACCOUNT
      this.userProfile.email = data.email
      this.userProfile.fullName = data.lastname + ' ' + data.firstname
      this.userProfile.gender = data.gender
      this.userProfile.phone = data.phone
      this.userProfile.birthDateFormatted = data.birth_date
        ? dateUtils.formatDate(
          data.birth_date,
          dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
          dateUtils.STATIC_FORMAT_DATE
        )
        : null
      this.birthDate = !functionUtils.validateStringIsNull(data.birth_date)
        ? dateUtils.formatDate(data.birth_date, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE, 'YYYY-MM-DD') : ''
      this.userProfile.roleIds = data.group && data.group.id
      this.userProfile.avatarUrl = data && data.url_avatar
    }
  },
  created () {
    let userId = this.$route.params.id
    this.userProfile.id = userId
    if (!functionUtils.isNull(userId) && userId !== 0) {
      this.SET_USER_DATA()
      this.GET_GROUP_LIST()
    }
  },
  methods: {
    isModerator: function () {
      return functionUtils.hasSystemRole(RoleType.MODERATOR, this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Required value
     */
    requiredValue (value) {
      if (value !== null && value !== undefined && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Save user profile
     */
    saveUserProfile: function () {
      if (this.$refs.form.validate()) {
        if (!functionUtils.validateEmail(this.userProfile.email)) {
          this.ON_SHOW_TOAST({
            'message': this.$t('register.emailInvalidate'),
            'styletype': ToastType.ERROR
          })
        } else if (!functionUtils.validatePhone(this.userProfile.phone)) {
          this.ON_SHOW_TOAST({
            'message': this.$t('register.phoneInvalidate'),
            'styletype': ToastType.ERROR
          })
        } else {
          let lastName = functionUtils.getFirstLastName(this.userProfile.fullName)
            .lastName
          let firstName = functionUtils.getFirstLastName(this.userProfile.fullName)
            .firstName
          let filter = {
            lastName: lastName,
            firstName: firstName,
            // birthDate: this.userProfile.birthDateFormatted,
            birthDate: !functionUtils.validateStringIsNull(this.userProfile.birthDateFormatted)
              ? dateUtils.formatDate(this.userProfile.birthDateFormatted, 'DD/MM/YYYY', 'YYYY-MM-DD') : null,
            phone: this.userProfile.phone,
            gender: this.userProfile.gender,
            groupId: this.userProfile.roleIds,
            email: this.userProfile.email,
            avatar_url: !functionUtils.validateStringIsNull(this.userProfile.avatarUrl)
              ? this.userProfile.avatarUrl : null
          }
          this.UPDATE_USER_PROFILE({ id: this.userProfile.id, ...filter })
            .then(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
                this.SET_USER_DATA()
              }.bind(this)
            )
            .catch(
              function (error) {
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    'message': this.$t(`${errorText}`),
                    styletype: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    message: this.$t('login.somethingWentWrong'),
                    styletype: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
        }
      }
    },
    /**
     * Log out
     */
    onLogOut: function () {
      let token = isCookieEnabled() ? getCookie('stock_token') : null
      this.LOGOUT(token)
        .then(
          function () {
            this.$router.push({
              name: 'login'
            })
          }.bind(this)
        )
        .catch(
          function () {
            this.$router.push({
              name: 'login'
            })
          }.bind(this)
        )
    },
    /**
     * Close
     */
    onClose: function () {
      window.history.back()
    },
    onShowChangePassword: function () {
      this.$refs.changePasswordModal.onShowModal()
    },
    handleCompleted: function (avatarUrl) {
      this.userProfile.avatarUrl = avatarUrl
    },
    ...mapActions([
      'SET_USER_DATA',
      'LOGOUT',
      'UPDATE_USER_PROFILE',
      'ON_SHOW_TOAST',
      'GET_GROUP_LIST'
    ])
  }
}
</script>
<style scoped>

</style>
