<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('user.changePassword')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            lazy-validation>
            <v-layout wrap>
              <v-flex
                xs12
                sm12
                md12>
                <p>{{ $t("user.ChangePasswordModal.guide") }}</p>
              </v-flex>
              <v-flex
                xs12
                sm12
                md12>
                <v-text-field
                  :rules="[requiredValue]"
                  v-model="oldPassword"
                  :label="$t('user.ChangePasswordModal.enterOldPass')"
                  :name="$t('user.ChangePasswordModal.enterOldPass')"
                  prepend-icon="mdi-lock"
                  type="password"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm12
                md12>
                <v-text-field
                  id="password"
                  :rules="[requiredValue]"
                  v-model="newPassword"
                  :label="$t('user.ChangePasswordModal.enterNewPass')"
                  :name="$t('user.ChangePasswordModal.enterNewPass')"
                  prepend-icon="mdi-lock"
                  type="password"
                  class="required"
                  loading
                  @input="validatePassword(newPassword)"
                >
                  <template v-slot:progress>
                    <v-progress-linear
                      :value="progress"
                      :color="color"
                      absolute
                      height="7"
                    />
                  </template>
                </v-text-field>
                <!-- <span
                  :class="{ password_length_count : validPasswordLength, show_password_length : passwordTyped }"
                  class="password_length"
                >{{ passwordLength }}</span> -->
                <div
                  v-show="passwordTyped"
                  class="lnu_container">
                  <p :class="{ got_number: containNumber }">
                    {{ $t("user.digit") }}
                  </p>
                  <p :class="{ got_lowercase: containLowercase }">
                    {{ $t("user.lowercase") }}
                  </p>
                  <p :class="{ got_uppercase: containUppercase }">
                    {{ $t("user.uppercase") }}
                  </p>
                </div>
              </v-flex>
              <v-flex
                xs12
                sm12
                md12>
                <v-text-field
                  :rules="[requiredValue]"
                  v-model="confirmPassword"
                  :label="$t('user.ChangePasswordModal.confirmNewPass')"
                  :name="$t('user.ChangePasswordModal.confirmNewPass')"
                  prepend-icon="mdi-lock-check"
                  type="password"
                  class="required"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    isShowModal: false,
    oldPassword: null,
    newPassword: '',
    confirmPassword: null,
    isLoading: false,
    //
    passwordLength: 0,
    passwordTyped: false,
    containLowercase: false,
    containUppercase: false,
    containNumber: false,
    validPasswordLength: false,
    validPassword: false,
    //
    custom: true
  }),
  computed: {
    progress () {
      return Math.min(100, this.newPassword.length * 12.5)
    },
    color () {
      return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
    },
    ...mapGetters(['GET_SNACK_BAR'])
  },
  methods: {
    /**
     * Required value
     */
    requiredValue (value) {
      if (value !== null && value !== undefined && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    onShowModal: function () {
      this.isShowModal = true
      this.oldPassword = null
      this.newPassword = ''
      this.confirmPassword = null
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    validatePassword: function (password) {
      this.passwordLength = password.length

      if (this.passwordLength > 7) {
        this.validPasswordLength = true
      } else {
        this.validPasswordLength = false
      }

      if (this.passwordLength > 0) {
        this.passwordTyped = true
      } else {
        this.passwordTyped = false
      }

      this.containLowercase = /[a-z]/.test(password)
      this.containUppercase = /[A-Z]/.test(password)
      this.containNumber = /\d/.test(password)

      if (
        this.containLowercase === true &&
        this.containUppercase === true &&
        this.containNumber === true &&
        this.validPasswordLength === true
      ) {
        this.validPassword = true
      } else {
        this.validPassword = false
      }
    },
    validateNewPassword: function () {
      let results = 'OK'
      if (!this.validPassword) {
        results = this.$t(
          'user.ChangePasswordModal.weakPassError'
        )
        return results
      } else if (this.newPassword !== this.confirmPassword) {
        results = this.$t(
          'user.ChangePasswordModal.compareNewPassAndConfirmPassError'
        )
      }
      return results
    },
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        let results = this.validateNewPassword()
        if (results === 'OK') {
          let data = {
            old_password: this.oldPassword,
            new_password: this.newPassword
          }
          this.CHANGE_PASSWORD_CURRENT_USER(data)
            .then(
              function () {
                this.isShowModal = false
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
              }.bind(this)
            )
            .catch(
              function (error) {
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    'message': this.$t(`${errorText}`),
                    styletype: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    'message': this.$t('login.somethingWentWrong'),
                    styletype: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
        } else {
          this.ON_SHOW_TOAST({
            message: results,
            styletype: ToastType.ERROR
          })
        }
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'CHANGE_PASSWORD_CURRENT_USER'
    ])
  }
}
</script>
<style scoped>
.lnu_container {
  display: block;
  margin: 0px auto;
  margin-bottom: 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}
.lnu_container p {
  width: 30%;
  height: auto;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  border-radius: 2px;
  color: rgba(71, 87, 98, 0.8);
  background: linear-gradient(to right, #06a65a 50%, #eee 50%);
  background-size: 200% 100%;
  background-position: right;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.got_lowercase,
.got_uppercase,
.got_number {
  background-position: left !important;
  color: rgba(255, 255, 255, 0.9) !important;
}
.show_password_length {
  display: block !important;
}
.password_length {
  padding: 2px 8px;
  position: absolute;
  top: 42px;
  right: 21px;
  -webkit-transform: translate(-50%) !important;
  transform: translate(-50%) !important;
  background: #dd4b39;
  color: white;
  border-color: #d73925;
  border-radius: 4px;
  font-size: 13px;
  display: none;
  -webkit-transition: all 0.1s !important;
  transition: all 0.1s;
}
.password_length_count {
  background: #06a65a;
  color: rgba(255, 255, 255, 0.9);
}
</style>
